.card {
  height: 100%;
  background-color: var(--body-bg);
  border-radius: var(--border-radius-m);
  overflow: hidden;
  box-shadow: 0 0 10px rgb(0, 0, 0, .2);

  &__img {

    & > a {
      display: block;
      overflow: hidden;
      border-radius: var(--border-radius-m);
    }

    img {
      display: block;
      background-color: var(--body-bg-thirdary);
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
      aspect-ratio: 2/1;
      transition: transform var(--transition-time-l);
    }

    &:hover img {
      transform: scale(1.1);
    }
  }

  &__content {
    display: block;
    padding: 1.5rem;
  }

  &__actions {
    &__item {
      color: inherit;
      display: inline-block;
      cursor: pointer;
      transition: color var(--transition-time-m);

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:hover {
        color: var(--primary);
      }
    }
  }
}
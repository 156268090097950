#toast-root {
  position: fixed;
  top: calc(var(--header-height) + 1.5rem);
  left: 50%;
  transform: translateX(-50%);

  @include media-breakpoint-down(md) {
    top: calc(var(--header-height-mobile) + 1.5rem);
  }
}

.toast {
  min-width: 10vw;
  width: 100%;
  display: block;
  padding: 1rem;
  border-radius: var(--border-radius-m);
  text-align: center;
  transition: var(--transition-time-l);
  opacity: 0;
  transform: translateY(calc(-100% - 1.5rem));

  &:not(:last-child) {
    margin-bottom: .5rem;
  }

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  &--success {
    background-color: var(--primary);
    color: var(--primary-color);
  }

  &--error {
    background-color: var(--bs-danger);
  }

  &--warning {
    background-color: var(--bs-warning);
  }
}
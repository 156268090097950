.footer {
  padding: 1rem 0;
  border-top: 1px solid var(--body-bg-secondary);

  &__nav {
    &__item {
      cursor: pointer;

      &:hover {
        color: inherit;
        text-decoration: underline;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}
@font-face {
  font-family: "Averta CY";
  src: url('/assets/fonts/Averta Light.eot');
  src: url('/assets/fonts/Averta Light.woff') format('woff');
  src: url('/assets/fonts/Averta Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta CY";
  src: url('/assets/fonts/Averta Regular.eot');
  src: url('/assets/fonts/Averta Regular.woff') format('woff');
  src: url('/assets/fonts/Averta Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta CY";
  src: url('/assets/fonts/Averta Semibold.eot');
  src: url('/assets/fonts/Averta Semibold.woff') format('woff');
  src: url('/assets/fonts/Averta Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Averta CY";
  src: url('/assets/fonts/Averta Bold.eot');
  src: url('/assets/fonts/Averta Bold.woff') format('woff');
  src: url('/assets/fonts/Averta Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Averta CY', sans-serif;
  font-weight: 400;
}

h1 {
  margin-bottom: var(--section-space);
}

a, .link {
  color: inherit;
  transition: color 300ms;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--primary);
  }

  &--visible {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
}

.btn {
  display: inline-block;
  border: none;
  outline: none;
  padding: 1rem 1.5rem;
  text-decoration: none;
  border-radius: var(--border-radius-m);
  transition: background-color 300ms, border-color 300ms;

  &--primary {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--primary-color);

    &:hover {
      background-color: var(--primary--shaded);
      color: var(--primary-color);
    }
  }

  &--secondary {
    background-color: transparent;
    border: 1px solid var(--primary);

    &:hover {
      background-color: var(--primary--shaded);
      color: inherit;
    }
  }

  &:disabled {
    border-color: var(--body-bg-thirdary);
    background-color: var(--body-bg-thirdary);
    cursor: not-allowed;

    &:hover {
      background-color: var(--body-bg-thirdary);
    }
  }
}

hr {
  color: var(--body-bg-secondary);
  opacity: 1;
}

.slider {
  &-container {
    display: block;
    height: 100% !important;
  }

  &-frame {
    height: 100% !important;
  }

  &-list {
    height: 100% !important;
  }

  &-control-centerright,
  &-control-centerleft {
    display: none;
  }
}
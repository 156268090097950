.header {
  position: fixed;
  width: calc(100% - var(--container-space-x));
  height: calc(var(--header-height) - 1.5rem);
  top: calc(var(--container-space-x) / 2);
  left: calc(var(--container-space-x) / 2);
  z-index: var(--z-index-header);

  @include media-breakpoint-down(md) {
    height: calc(var(--header-height-mobile) - 1.5rem);
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    padding: 0 calc(var(--section-space) / 2);
    background-color: var(--primary);
    color: var(--primary-color);
    overflow: hidden;
    border-radius: var(--border-radius-l);
  }

  &__branding {
    font-size: 1.5rem;
  }

  &__nav__item, & a {
    cursor: pointer;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
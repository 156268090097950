@import "variables";

@import "modules/bootstrap";

@import "modules/typo";
@import "modules/layouts";
@import "modules/header";
@import "modules/footer";
@import "modules/form";
@import "modules/card";
@import "modules/hero";
@import "modules/slider";
@import "modules/toast";

body {
  background-color: var(--body-bg);
  color: var(--body-color);
  color-scheme: dark;
}

img {
  background-color: var(--body-bg-secondary);
  width: 100%;
  height: auto;
}
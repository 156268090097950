$container-space-x: 3rem;

:root {
  --primary: #0c9869;
  --primary--shaded: #088159;
  --primary-color: #fff;
  --secondary: #fec282;
  --secondary--shaded: #e58f51;
  --secondary-color: #000;
  --body-bg: #1e1e1e;
  --body-bg-secondary: #414141;
  --body-bg-thirdary: #737373;
  --body-color: #ffffff;
  --body-color-secondary: #cbcbcb;
  --body-color-thirdary: #b2b2b2;

  --border-radius-s: .5rem;
  --border-radius-m: 1rem;
  --border-radius-l: 1.5rem;
  --border-radius-xl: 3rem;

  --transition-time-s: 150ms;
  --transition-time-m: 300ms;
  --transition-time-l: 900ms;

  --z-index-header: 2000;

  --section-space: 3rem;

  --container-space-x: #{$container-space-x};

  --header-height: 6.5rem;
  --header-height-mobile: 8rem;
}
.hero {
  position: relative;
  width: 100%;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__gallery {
    border-radius: 0 0 var(--border-radius-xl) var(--border-radius-xl);
    height: 75vh;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      height: 60vh;
    }
  }

  &__actions {
    position: absolute;
    bottom: var(--container-space-x);
    right: var(--container-space-x);

    &__item {
      background-color: var(--primary);
      border-radius: var(--border-radius-m);
      color: var(--primary-color);
      padding: 1rem;
      overflow: hidden;
      display: inline-block;
      cursor: pointer;
      transition: color var(--transition-time-m), background-color var(--transition-time-m);

      @include media-breakpoint-up(md) {
        font-size: 1.5em;
      }

      &:not(:last-child) {
        margin-right: 1.5rem;
      }

      &:hover {
        color: var(--primary-color);
        background-color: var(--primary--shaded);
      }
    }
  }
}
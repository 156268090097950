.form-group {
  label {
    display: block;
    margin-bottom: .5rem;
  }

  input, textarea {
    display: block;
    width: 100%;
    padding: 1rem 1.5rem;
    border: none;
    outline: none;
    // background-color: var(--body-bg-secondary);
    border-radius: var(--border-radius-s);
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.form {
  position: relative;
  transition: opacity var(--transition-time-l);
  opacity: 1;

  &--loading {
    border-radius: var(--border-radius-m);
    overflow: hidden;

    .form__wrapper {
      opacity: .5;
      cursor: not-allowed;

      & > * {
        pointer-events: none;
      }
    }
  }
}

.dropzone {
  position: relative;
  width: 100%;
  text-align: center;
  border: 2px dashed var(--body-color-thirdary);
  border-radius: var(--border-radius-m);
  padding: calc(var(--section-space) * 2) var(--section-space);
  cursor: pointer;
  overflow: hidden;

  & > p:last-child {
    margin-bottom: 0;
  }
}

.image-preview {
  img {
    max-height: 4rem;
    width: auto;
    aspect-ratio: 2/1;
    object-fit: cover;
    object-position: center;
    border-radius: var(--border-radius-s);
  }
}
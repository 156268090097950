@import "../../../node_modules/bootstrap/scss/functions";

@import "../../../node_modules/bootstrap/scss/variables";

@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";

.mvl-container {
  @include make-container(#{$container-space-x * 2});
}

.header-container {
  @include make-container(#{$container-space-x});
}

@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/helpers";

@import "../../../node_modules/bootstrap/scss/utilities/api";
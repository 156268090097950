.section {
  margin-top: var(--section-space);
  margin-bottom: var(--section-space);

  &--first {
    margin-top: calc(var(--section-space) + var(--header-height));
    margin-bottom: var(--section-space);

    @include media-breakpoint-down(md) {
      margin-top: calc(var(--section-space) + var(--header-height-mobile));
    }
  }

  &--hero {
    margin-top: 0;
    padding-top: 0;
  }
}